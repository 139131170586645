// import React,{useState,useEffect} from 'react';


// const experienceItems = [
//   {
//     jobTitle: 'Frontend Developer',
//     company: 'Abhyam Robotics Institute of Business And Techology',
//     date: '2023 Feb - Present',
//     responsibilities: [
//       'Developed and maintained frontend code for school, restaurant and pharmacy management systems',
//       'Designed UI/UX for various projects',
//       'Collaborated with designers, backend developers and project managers to implement new features and ensure timely delivery of projects',
//       'Optimized website and application performance by implementing best practices and monitoring metrics'
//     ],
//     type:"Intern"
//   },
 
// ];


// function Experience() {
//   const [isLoaded, setIsLoaded] = useState(false);

//   useEffect(() => {
//     setIsLoaded(true);
//   }, []);
//   return (
//     <div className={`experience-container px-5 xl:px-32 2xl:px-32 py-10 ${isLoaded ? 'fade-in' : ''}`}>
//     <div className="flex flex-col   h-full w-full   space-y-10 ">
//       <h1 className="tittle  text-center">Experience</h1>
//       {experienceItems.map((item) => (
//         <div key={item.jobTitle} className="bg-white  text-main rounded-lg shadow-lg p-6 w-full  space-y-4">
//           <div className="flex space-x-4 items-center">
           
           
//             <div>
//               <h2 className="text-xl font-bold animate-bounce mb-2">{item.jobTitle}</h2>
//               <p className=" text-base font-medium">{item.company}</p>
//               <p className=" text-sm flex items-center space-x-5">
//                 <span>{item.date}</span>
//                 <span className="inline-flex items-center justify-center px-6  py-2 navLinks rounded-full">
                  
//                   <span className="text-white text-sm">{item.type}</span>
//                 </span>
//               </p>
//             </div>
//           </div>
//           <ul className="ml-8 list-disc list-outside space-y-2">
//             {item.responsibilities.map((responsibility, index) => (
//               <li key={index} className="py-1 flex items-center space-x-2">
                
//                 <span className='w-2 h-2 rounded-full navLinks'></span><span>{responsibility}</span>
//               </li>
//             ))}
//           </ul>
//         </div>
//       ))}
//     </div>
//      </div>
//   );
// }

// export default Experience;


import React from 'react';

const experienceItems = [
  {
    jobTitle: 'Frontend Developer',
    company: 'Abhyam Robotics Institute of Business And Techology',
    date: '2023 Feb - Present',
    responsibilities: [
      'Developed and maintained frontend code for school, restaurant and pharmacy management systems',
      'Designed UI/UX for various projects',
      'Collaborated with designers, backend developers and project managers to implement new features and ensure timely delivery of projects',
      'Optimized website and application performance by implementing best practices and monitoring metrics'
    ],
    type:"Intern"
  },
];

function Experience() {
 

  

  return (
    <div className={` px-5 xl:px-32 2xl:px-32 py-10 `}>
      <div className="flex flex-col space-y-10">
        <h1 className="tittle text-center text-3xl lg:text-4xl xl:text-5xl font-bold">Experience</h1>
        {experienceItems.map((item) => (
          <div key={item.jobTitle} className="bg-white text-main rounded-lg shadow-lg p-6 space-y-4">
            <div className="flex flex-col md:flex-row md:items-center md:justify-between md:space-x-4">
              <div>
                <h2 className="text-xl md:text-2xl lg:text-3xl font-bold animate-bounce mb-2">{item.jobTitle}</h2>
                <p className="text-base md:text-lg font-medium">{item.company}</p>
                <p className="text-sm md:text-base flex items-center space-x-2">
                  <span>{item.date}</span>
                  <span className="inline-flex items-center justify-center px-3 py-1 navLinks rounded-full">
                    <span className="text-white text-sm md:text-base">{item.type}</span>
                  </span>
                </p>
              </div>
            </div>
            <ul className="ml-8 list-disc list-outside space-y-2">
              {item.responsibilities.map((responsibility, index) => (
                <li key={index} className="py-1 flex items-center space-x-2">
                  <span className='w-2 h-2 rounded-full navLinks'></span>
                  <span className="text-sm md:text-base">{responsibility}</span>
                </li>
              ))}
            </ul>
          </div>
        ))}
      </div>
    </div>
  );
}

export default Experience;
