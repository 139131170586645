import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import work1 from "../../Resources/work1.png";
import work2 from "../../Resources/hubit2.png";
import work3 from "../../Resources/rms1.png";
import work4 from "../../Resources/rms4.png";
import work5 from "../../Resources/ttt1.png";
import work6 from "../../Resources/ttt2.png";

const projects = [
  {
    id: 1,
    name: "Website For an IT Company",
    workPic1: work1,
    workPic2: work2,
    link: "http://www.hubit.com.np",
  },
  {
    id: 2,
    name: "Restaurant Management System",
    workPic1: work3,
    workPic2: work4,
    link: "/Workrms",
  },
  {
    id: 3,
    name: "Tic Tac Toe Game",
    workPic1: work5,
    workPic2: work6,
    link: "https://tictactoe-game-8ghzs3189-arbhram.vercel.app/",
  },
];

export const Work = () => {
  const [hoveredCardId, setHoveredCardId] = useState(null);
  const navigate = useNavigate();

  return (
    <div className="text-main max-w-screen-lg mx-auto px-4 sm:px-6 lg:px-8 py-10 space-y-10">
      <div className="py-4">
        <div className="container mx-auto flex flex-col justify-between items-center">
          <h1 className="tittle">My Projects</h1>
        </div>
      </div>
      <div className="container text-main mx-auto ">
        <div className="grid grid-cols-1  sm:grid-cols-2 md:grid-cols-3 gap-y-12 gap-x-8">
          {projects.map((project) => (
            <div
              key={project.id}
              onMouseEnter={() => setHoveredCardId(project.id)}
              onMouseLeave={() => setHoveredCardId(null)}
              className="shadow-custom overflow-hidden bg-white rounded-lg space-y-4 cursor-pointer transition-all duration-100 ease-in-out transform"
              style={{
                height: project.id === hoveredCardId ? "auto" : "250px",
              }}
            >
              <h2 className="text-sm text-center font-medium linear py-2 my-2 mx-4 rounded text-white">
                {project.name}
              </h2>
              <div className="flex flex-col">
                <div
                  className={`transition-all w-hull h-32 duration-300 block relative left-20 border`}
                >
                  <img
                    src={project.workPic1}
                    alt=""
                    className="h-full w-full"
                  />
                </div>
                <div
                  className={`transition-all w-hull h-28 duration-300 block absolute top-32 z-10 right-20 border`}
                >
                  <img
                    src={project.workPic2}
                    alt=""
                    className="h-full w-full"
                  />
                </div>
              </div>
              <div
                className={`${
                  project.id === hoveredCardId
                    ? "translate-y-0"
                    : "-translate-y-full"
                } transition-all duration-300 flex items-center justify-center z-20 text-white fixed bottom-0 w-full h-[250px] linear opacity-90`}
              >
                <button
                  onClick={() => {
                    if (project.link === "/Workrms") {
                      navigate("/Workrms");
                    } else {
                      window.open(project.link, "_blank");
                    }
                  }}
                  className="bg-gray-900 text-white rounded-full py-2 px-4 hover:bg-gray-800 transition-all duration-200"
                >
                  {project.link === "/Workrms" ? "View More" : "Visit Site"}
                </button>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Work;
