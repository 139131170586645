import React, { useState } from "react";
import { RxCross2 } from "react-icons/rx";
import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom";
export const LittleSideNav = ({ setOpenSideNav,openSideNav }) => {
  const Location = useLocation();
  const navLinks = [
    { path: "/", label: "Home" },
    { path: "/about", label: "About" },
    { path: "/services", label: "Services" },
    { path: "/skill", label: "Skill" },
    { path: "/education", label: "Education" },
    { path: "/exprience", label: "Experience" },
    { path: "/work", label: "Work" },
    { path: "/blog", label: "Blog" },
    { path: "/contact", label: "Contact" },
  ];
  const [hover, setHover] = useState(false);
  return (

 
  <>
  
      <div className={`bg-main ${ openSideNav ? "translate-x-0":"translate-x-full "} h-screen transition-all duration-300 shadow-custom z-50 linear w-fit fixed right-0  text-white py-10 space-y-8 flex flex-col  items-between `}>
      
       <div  className="absolute text-2xl left-10">
        <button onClick={()=>setOpenSideNav(false)}>
        <RxCross2/>
        </button>
       </div>
        <div className="flex w-full  h-fit  flex-col  gap-4  text-sm font-light">
          {navLinks.map((val, i) => {
            return (
              <div
                key={i}
                onMouseEnter={() => {
                  setHover(true);
                  setHover(val.label);
                }}
                onMouseLeave={() => setHover(false)}
                className="transition-all duration-300 "
              >
                <Link to={val.path}>
                  <button
                    key={i}
                    className={`${
                      hover === val.label || Location.pathname === val.path
                        ? "navLinks"
                        : ""
                    } rounded-lg w-full py-2 `}
                  >
                    {val.label}
                  </button>
                </Link>
              </div>
            );
          })}
        </div>
        <div className="text-center w-full h-full px-5 text-[10px] ">
          <p>
            Copyright ©2023 All rights reserved | This Portfolio is made by
            Aryan
          </p>
        </div>
      </div>
  
  </>
   )
};
