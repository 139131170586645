import React from 'react';
import figmaIcon from '../../Resources/figmaIcon.png';
import reactIcon from '../../Resources/reactIcon.png';
import javaScriptIcon from '../../Resources/javaScriptIcon.png';
import tailwindIcon from '../../Resources/tailwindIcon.png';
import nodeIcon from '../../Resources/nodeIcon.png';
import graphicIcon from '../../Resources/graphicIcon.png';

const skills = [
  { icon: reactIcon, label: 'React JS', percent: 80 },
  { icon: tailwindIcon, label: 'Tailwind CSS', percent: 90 },
  { icon: nodeIcon, label: 'Node JS', percent: 75 },
  { icon: figmaIcon, label: 'Figma', percent: 70 },
  { icon: graphicIcon, label: 'Photoshop', percent: 65 },
  { icon: javaScriptIcon, label: 'JavaScript', percent: 65 },
];

export const MySkills = () => {
  return (
    <div className="px-8 sm:px-16 md:px-32 py-10 space-y-10">
       <div className="container mx-auto flex flex-col justify-between items-center">
          <h1 className="tittle">My Skills</h1>
         
        </div>
      <div className="grid grid-cols-1  sm:grid-cols-2 md:grid-cols-2 gap-20">
        {skills.map((skill, i) => (
          <div key={i} className="flex flex-col space-y-5 items-center gap-2">
            <div className="w-full flex justify-between items-center">
              <div className="flex items-center text-white gap-2 cursor-pointer hover:rotate-6 rounded transition-all duration-100 animate-bounce px-2 py-1 linear shadow-custom">
                <div className="h-5 w-5">
                  <img src={skill.icon} alt="" />
                </div>
                <div className="text-xs">{skill.label}</div>
              </div>
              <span className="text-xl sm:text-2xl font-oregano text-main font-semibold">
                {skill.percent}%
              </span>
            </div>
            <div className="w-full bg-gray-300 h-2 rounded-full ">
              <div
                className="h-full rounded-full linear"
                style={{ width: `${skill.percent}%` }}
              ></div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};
