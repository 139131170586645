import React, { useState } from "react";

const Education = () => {
  const educationData = [
    {
      schoolName: "Horizon Gautam Buddha Secondary School",
      degreeTopic: "Secondary Education Examination(SEE)",
      gpa: "4",
      description:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed imperdiet euismod urna, in rhoncus eros lobortis in. In hac habitasse platea dictumst.",
    },
    {
      schoolName: "Horizon Gautam Buddha Secondary School",
      degreeTopic: "School Leaving Certificate(SLC)",
      gpa: "3.12",
      description:
        "Vestibulum id justo ut mauris efficitur pharetra. Sed vitae posuere mi. Proin posuere, quam ut varius semper, enim magna commodo augue, ut finibus risus velit eu mauris.",
    },
  ];

  const [activeIndex, setActiveIndex] = useState(0);

  const toggleDescription = (index) => {
    setActiveIndex(activeIndex === index ? -1 : index);
  };

  return (
    <div className="flex flex-col gap-10 py-10 px-5 lg:px-32">
      <div className="flex justify-center">
        <p className="tittle">Education Journey</p>
      </div>
      <div className="space-y-5">
      {educationData.map((val, i) => (
        <div
          className=" navLinks  text-white rounded shadow-sm overflow-hidden "
          key={i}
        >
          <div
            className="p-4 cursor-pointer"
            onClick={() => toggleDescription(i)}
          >
            <p className="font-medium text-xl ">{val.degreeTopic}</p>
            


          
          </div>
          <div className="bg-white text-black">
          {activeIndex === i && (
            <div className="flex flex-col p-4  animate-dropdown ">
              <p className="text-xl  font-semibold">{val.schoolName}<span className="mx-2 text-xl  font-medium">GPA-{val.gpa}</span></p>
              <p>{val.description}</p>
            </div>
          )}
          </div>
        </div>
      ))}
      </div>
    </div>
  );
};
export default Education;
