import React from "react";
import figmaIcon from "../../Resources/figmaIcon.png";
import htmlIcon from "../../Resources/htmlIcon.png";
import reactIcon from "../../Resources/reactIcon.png";
import javaScriptIcon from "../../Resources/javaScriptIcon.png";
import cssIcon from "../../Resources/cssIcon.png";
import tailwindIcon from "../../Resources/tailwindIcon.png";
import nodeIcon from "../../Resources/nodeIcon.png";
import graphicIcon from "../../Resources/graphicIcon.png";
import { RxHamburgerMenu } from "react-icons/rx";


const SideNav = ({setOpenSideNav}) => {
  const skillIcon = [
    {
      icon: reactIcon,
      label: "React",
    },
    {
      icon: figmaIcon,
      label: "Figma",
    },
    {
      icon: htmlIcon,
      label: "HTML 5",
    },
    {
      icon: javaScriptIcon,
      label: "JavaScript",
    },
    {
      icon: cssIcon,
      label: "CSS",
    },
    {
      icon: tailwindIcon,
      label: "Tailwind",
    },
    {
      icon: graphicIcon,
      label: "Desinger",
    },
    {
      icon: nodeIcon,
      label: "Node JS",
    },
  ];

  return (
    <div className="">
      
      <div className="hidden xl:flex 2xl:flex linear items-center w-full justify-between text-white px-10 rounded-lg py-3">
        {skillIcon.map((val, i) => {
          return (
            <div
              key={i}
              className="flex items-center gap-2 cursor-pointer hover:rotate-6 rounded transition-all duration-100 bg-orange px-2 py-1 linear shadow-custom"
            >
              <div className="h-5 w-5">
                <img src={val.icon} alt="" />
              </div>
              <div className="text-xs">{val.label}</div>
            </div>
          );
        })}
      </div>
     
      <div className="mx-auto flex xl:hidden 2xl:hidden linear items-center justify-between text-white px-10 rounded-lg py-3 w-full">
        <p className="font-oregano text-3xl font-light text-center ">
          aryanDev
        </p>
        <button onClick={() => setOpenSideNav(true)} className="text-2xl">
          <RxHamburgerMenu />
        </button>
      </div>
    </div>
  );
};

export default SideNav;
