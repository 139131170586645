import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom";
export const TopNav = () => {
  const Location = useLocation();
  const navLinks = [
    { path: "/", label: "Home" },
    { path: "/About", label: "About" },
    { path: "/Services", label: "Services" },
    { path: "/Skill", label: "Skill" },
    { path: "/Education", label: "Education" },
    { path: "/Experience", label: "Experience" },
    { path: "/Work", label: "Work" },
    { path: "/Blog", label: "Blog" },
    { path: "/Contact", label: "Contact" },
  ];
  const [hover, setHover] = useState(false);
  return (
    <div className="p-1  h-screen">
      <div className="bg-main shadow-custom linear h-full w-full relative  rounded-xl text-white space-y-20 py-10 px-5 flex flex-col  items-between ">
      
        <div className="mx-auto w-full">
          <p className="font-oregano text-3xl font-light text-center ">
            {" "}
            aryanDev
          </p>
        </div>
        <div className="flex w-full  h-fit  flex-col my-6 gap-4  text-sm font-light">
          {navLinks.map((val, i) => {
            return (
              <div
                key={i}
                onMouseEnter={() => {
                  setHover(true);
                  setHover(val.label);
                }}
                onMouseLeave={() => setHover(false)}
                className="transition-all duration-300 "
              >
                <Link to={val.path}>
                  <button
                    key={i}
                    className={`${
                      hover === val.label || Location.pathname === val.path
                        ? "navLinks"
                        : ""
                    } rounded-lg w-full py-2 `}
                  >
                    {val.label}
                  </button>
                </Link>
              </div>
            );
          })}
        </div>
        <div className="text-center w-full h-fit text-[10px] ">
          <p>
            Copyright ©2023 All rights reserved | This Portfolio is made by
            Aryan
          </p>
        </div>
      </div>
    </div>
  );
};



