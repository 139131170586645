import React, { useState } from "react";
import image1 from "../../Resources/rms1.png";
import image2 from "../../Resources/rms2.png";
import image3 from "../../Resources/rms3.png";
import image4 from "../../Resources/rms4.png";
const images = [image1, image2, image3,image4];

export const RMSwork = () => {
  const [currentIndex, setCurrentIndex] = useState(0);

  const handlePrevClick = () => {
    setCurrentIndex((prevIndex) => (prevIndex === 0 ? images.length - 1 : prevIndex - 1));
  };

  const handleNextClick = () => {
    setCurrentIndex((prevIndex) => (prevIndex === images.length - 1 ? 0 : prevIndex + 1));
  };

  const handleDotClick = (index) => {
    setCurrentIndex(index);
  };

  return (
    <div className="relative px-5 xl:px-32 2xl:px-32 py-10 space-y-10">
      <div className="">
        <p className=" text-center">Some ScreenShots of Restaurant Management Systems</p>
      </div>
      <div className="flex flex-col items-center justify-center gap-5 mb-4">
      <img
          src={images[currentIndex]}
          alt={` ${currentIndex + 1}`}
          className="max-w-full h-auto rounded-lg shadow-custom"
        />
        <div className="flex items-center justify-between w-full gap-4">
          <div className="flex items-center justify-center gap-2">
        <button
          onClick={handlePrevClick}
          className="second-button"
        >
          Prev
        </button>
        <div className="w-full h-full shadow-custom rounded-lg">
        
        </div>
        <button
          onClick={handleNextClick}
          className="second-button"
        >
          Next
        </button>
        </div>
        <div className="flex justify-center">
        {images.map((_, index) => (
          <button
            key={index}
            onClick={() => handleDotClick(index)}
            className={`w-2 h-2 rounded-full mx-1 focus:outline-none ${
              currentIndex === index
                ? "linear"
                : "bg-gray-400 hover:bg-gray-600"
            }`}
          />
        ))}
      </div>
        </div>
      </div>
      
    </div>
  );
};
