import React from "react";
const ExpertiseCard = ({ myExpertise }) => {
  return (
    <>
      {myExpertise.map((val, i) => {
        return (
          <div key={i} className="flex gap-10 items-center cursor-pointer justify-between shadow-custom rounded-lg  w-full h-full  flex-col">
            <div className=" navLinks w-full p-6 flex justify-center     rounded-t-xl ">
              <span className="text-white mx-auto  text-[80px]">
                <p className="">
                {val.icon}
                </p>
              </span>
            </div>

            <div className="">
              <p className="text-center text-sm ">{val.description}</p>
            </div>
            <div className="w-full  linear rounded-b-lg py-5">
              <p className="text-lg text-white text-center">{val.label}</p>
            </div>
          </div>
        );
      })}
    </>
  );
};

export default ExpertiseCard;
