import React from "react";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";

const validationSchema = Yup.object().shape({
  name: Yup.string().required("Name is required"),
  email: Yup.string().email("Invalid email").required("Email is required"),
  message: Yup.string().required("Message is required"),
});

export const Contact = () => {
  const initialValues = { name: "", email: "", message: "" };

  const handleSubmit = (values, { resetForm }) => {
  
    console.log(values);


    resetForm();
  };

  return (
    <div className="flex flex-col py-10 items-center space-y-10 px-5 xl:px-32 2xl:px-32 ">
      <h1 className="tittle text-center">Contact Me</h1>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({ isSubmitting }) => (
          <Form className="w-full max-w-lg">
            <div className="mb-4">
              <label
                className="block text-gray-700 font-bold mb-2"
                htmlFor="name"
              >
                Name
              </label>
              <Field
                id="name"
                name="name"
                type="text"
                placeholder="Your name"
                className="appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              />
              <ErrorMessage
                name="name"
                component="p"
                className="text-red-500 text-xs italic"
              />
            </div>

            <div className="mb-4">
              <label
                className="block text-gray-700 font-bold mb-2"
                htmlFor="email"
              >
                Email
              </label>
              <Field
                id="email"
                name="email"
                type="email"
                placeholder="Your email"
                className="appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              />
              <ErrorMessage
                name="email"
                component="p"
                className="text-red-500 text-xs italic"
              />
            </div>

            <div className="mb-6">
              <label
                className="block text-gray-700 font-bold mb-2"
                htmlFor="message"
              >
                Message
              </label>
              <Field
                id="message"
                name="message"
                component="textarea"
                placeholder="Your message"
                className="appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              />
              <ErrorMessage
                name="message"
                component="p"
                className="text-red-500 text-xs italic"
              />
            </div>

            <div className="flex items-center justify-center">
              <button type="submit" disabled={isSubmitting} 
              className="px-10 py-2 rounded  navLinks">
                {isSubmitting ? "Submitting..." : "Submit"}
              </button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};
