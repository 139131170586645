export const Describing = [
  {
    category: "myself",
    label1st: "Designer",
    label2nd: "<Coder/>",
    description:
      "Hi, I am Aryan Dev Pandey, A MERN full-stack developer with expertise in building responsive and scalable web applications. Proficient in HTML, CSS (with Tailwind), JavaScript, and React.js on the frontend, and Node.js on the backend. I also possesses a working knowledge of Figma and Photoshop for design and UI/UX.",
  },
  {
    category: "passion",
    label1st: "Creativity",
    label2nd: "Problem Sloving",
    description:
      "I'm passionate about building innovative and intuitive web applications using my expertise in MERN stack development. My proficiency in HTML, CSS (with Tailwind), JavaScript, and React.js on the frontend, as well as Node.js on the backend, allows me to create responsive and scalable designs. I also have a working knowledge of design tools such as Figma and Photoshop, which help me deliver seamless UI/UX experiences.",
  },
  {
    category: "trivia",
    label1st: "Learning",
    label2nd: "Tech Enthusiast",
    description:
      "As a developer, I'm not only passionate about coding but also about exploring the latest advancements in data science, AI and machine learning. Apart from that, I have a keen interest in sci-fi movies and literature, which often inspires me to think creatively and approach problems from a unique perspective. In my free time, I enjoy learning about emerging technologies and experimenting with new tools and frameworks to enhance my skill set..",
  },
]