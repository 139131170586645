// import React from 'react'
// import ExpertiseCard from './ExpertiseCard'
// import {FaNode} from "react-icons/fa"
// import {AiFillHtml5,AiFillDatabase} from "react-icons/ai"
// function Service() {
  
//   const myExpertise = [
//     {
//       label: "Front-end development",
//       icon: <AiFillHtml5/>,
//       description: "Expertise in building responsive and user-friendly interfaces with React, Redux, and CSS."
//     },
//     {
//       label: "Back-end development",
//       icon: <FaNode />,
//       description: "Expertise in building RESTful APIs with Node.js, Express, and MongoDB."
//     },
//     {
//       label: "Database design",
//       icon: <AiFillDatabase />,
//       description: "Expertise in designing and optimizing MongoDB database schemas for efficient data storage and retrieval."
//     },
 
//   ];
//   const customer = "23"
//   return (
//     <div className='flex h-full flex-col px-32 gap-10 py-10'>
//        <div className="flex  w-full justify-center">
//         <p className="text-xl font-semibold">My Expertise</p>
//       </div>
//       <div className="grid mt-24  grid-cols-3 gap-y-32 gap-x-10">
        
//               <ExpertiseCard myExpertise={myExpertise} />
        
       
//       </div>
//       <div className="w-full h-fit py-5 text-xl flex justify-between px-10 items-center navLinks">
//         <div className="capitalize">
//         I am happy to know you that happy {customer} customer.
//         </div>
//         <div className="">
//           <button className="second-button">Hire Me</button>
//         </div>
//       </div>
//     </div>
//   )
// }

// export default Service
import React from 'react'
import ExpertiseCard from './ExpertiseCard'
import {FaNode} from "react-icons/fa"
import {AiFillHtml5,AiFillDatabase} from "react-icons/ai"

function Service() {
  
  const myExpertise = [
    {
      label: "Front-end development",
      icon: <AiFillHtml5/>,
      description: "Expertise in building responsive and user-friendly interfaces with React, Redux, and CSS."
    },
    {
      label: "Back-end development",
      icon: <FaNode />,
      description: "Expertise in building RESTful APIs with Node.js, Express, and MongoDB."
    },
    {
      label: "Database design",
      icon: <AiFillDatabase />,
      description: "Expertise in designing and optimizing MongoDB database schemas for efficient data storage and retrieval."
    },
 
  ];
 
  return (
    <div className='flex h-full flex-col gap-10 px-4 md:px-32 py-10'>
      <div className="flex w-full justify-center">
        <p className="tittle">My Expertise</p>
      </div>
      <div className="grid  grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-y-10 md:gap-y-28 gap-x-6 md:gap-x-10">
        <ExpertiseCard myExpertise={myExpertise} />
      </div>
      
    </div>
  )
}

export default Service
