import React from "react";
import { GrFacebookOption, GrInstagram } from "react-icons/gr";
import { FaLinkedinIn } from "react-icons/fa";
import profile from "../../Resources/profile.png";
import cv from "../../Resources/Aryan-Pandey-cv.pdf"
// import AboutPage from "../AboutPage/AboutPage";
// import Service from "../Service/Service";
// import { MySkills } from "../Skill/Skill";
// import Education from "../Education/Education";
// import { Work } from "../Work/Work";
// import Experience from "../Experience/Experience";

function HomePage() {
  const ability = [
    {
      label: "Design",
    },
    {
      label: "Development",
    },
    {
      label: "Web Flow",
    },
    {
      label: "Communication",
    },
  ];
  const socialIcon = [
    {
      label: <GrFacebookOption />,
      link: "https://www.facebook.com/aryandev.pandey.7",
    },
    {
      label: <FaLinkedinIn />,
      link: "https://www.linkedin.com/in/aryan-dev-pandey-03ab62255/",
    },
    {
      label: <GrInstagram />,
      link: "https://www.instagram.com/nameisaryan18/",
    },
  ];

  return (
    <>
      <div className="grid grid-cols-1 bg-white md:grid-cols-2  gap-6">
        <div className="  flex flex-col items-center justify-center pt-5 xl:pt-0 2xl:pt-0 lg:pt-0 ">
          <div className="text-center md:text-left">
            <h2 className="text-2xl font-bold mb-2">Hey!</h2>
            <p className="text-3xl">I am Aryan Dev Pandey</p>
            <div className="flex flex-wrap xl:flex-col 2xl:flex-col  lg:flex-col   gap-3 justify-center md:justify-start mt-4">
              {ability.map((val, i) => {
                return (
                  <div key={i} className="flex items-center gap-2">
                    <span className="w-2 h-2 rounded-full bg-main"></span>
                    <p>{val.label}</p>
                  </div>
                );
              })}
            </div>
            <div className="flex flex-wrap justify-center gap-4 md:justify-start mt-6">
              <a
                href={cv}
                download="Aryan_Dev_Pandey_CV.pdf"
                className="main-button"
              >
                Download CV
              </a>
              <button className="second-button">Let's Talk</button>
            </div>
            <div className="flex flex-wrap justify-center md:justify-start mt-6">
              {socialIcon.map((val, i) => {
                return (
                  <a
                    key={i}
                    href={val.link}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="border border-main hover:border-[#E14D2A] rounded-full hover:text-[#E14D2A] transition-all duration-300 text-main px-3 py-3 mr-4 mb-4 md:mb-0"
                  >
                    {val.label}
                  </a>
                );
              })}
            </div>
          </div>
        </div>
        <div className="flex items-center  w-full h-full justify-center">
          <img src={profile} alt="" className="w-full h-full" />
        </div>
      </div>
      {/* <AboutPage />
      <Service />
      <MySkills />
      <Education />
      <Work /> 
      <Experience /> */}
    </>
  );
}

export default HomePage;
