import React from "react";
import { useState } from "react";
import { Describing } from "./Describing";



function AboutPage() {
  const myself = [
    {
      label: "MySelf",
    },
    {
      label: "Passion",
    },
    {
      label: "Trivia",
    },
  ];

  const [show, setShow] = useState(myself[0].label);
  const [filter, setFilter] = useState(
    Describing.filter((elem) => elem.category.toLowerCase() === "myself")
  );

  const filterMyself = (ind) => {
    const updateFilter = Describing.filter((elem, index) => {
      return ind === index;
    });
    setFilter(updateFilter);
    setShow(true);
  };

  return (
    <div className="flex flex-col gap-10 py-10 px-5 lg:px-32">
      <div className="flex justify-center">
        <p className="tittle">About me</p>
      </div>
      <div className="flex flex-col gap-8 linear text-white rounded-lg h-96 py-5">
        <div className="flex justify-between space-x-5 items-start">
          {myself.map((val, i) => {
            return (
              <button
                onClick={() => {
                  filterMyself(i);
                  setShow(val.label);
                }}
                key={i}
                className="text-lg w-full justify-center items-center gap-2 flex flex-col mt-4 font-medium"
              >
                <p>{val.label}</p>
                <div className={`${show===true || show===val.label ? "flex":"hidden"} w-full h-1 rounded-full bg-white`}>

                </div>
              </button>
            );
          })}
        </div>
        {filter.map((val, i) => {
          return (
            <div
              key={i}
              className="h-full flex justify-between   items-center text-sm text-center"
            >
              <div className="text-xl text-white flex flex-col justify-center items-between gap-8">
                <div className="flex items-center justify-center gap-2">
                  <p>{val.label1st}</p>
                  <p>And</p>
                  <p className="text-[#337ab7]">{val.label2nd}</p>
                </div>
                <p className="text-sm">{val.description}</p>
              </div>
            </div>
          );
        })}
      </div>
      
    </div>
  );
}

export default AboutPage;

